const SiteBreadcrumb = ({ title, parent }) => {
  return (
    <>
      <div className="breadcrumb-main bg-light">
        <div className="custom-container">
          <ul>
            <li>
              <a href="#">{parent}</a>
            </li>
            <li>/</li>
            <li>
              <a href="#">{title}</a>
            </li>
          </ul>
          <div className="breadcrumb-contain">
            <div>
              <h2>{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteBreadcrumb;
