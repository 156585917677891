import { useEffect, useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  setLanguageCodeInSession,
} from "../../../../helpers/CommonHelper";
import GlobalEnums from "../../../../helpers/GlobalEnums";

const langCodeArray = [
  {
    langCode: "en",
    name: "Engligh",
  },
  // {
  //   langCode: "vn",
  //   name: "Vietnamese",
  // }
];

const Languages = () => {
  const [openLang, setOpenLang] = useState(false);
  const [url, setUrl] = useState("");
  const toggleLang = () => {
    setOpenLang(!openLang);
  };

  useEffect(() => {
    const path = window.location.pathname.split("/");
    const urlTemp = path[path.length - 1];
    setUrl(urlTemp);
  }, []);

  const [langCode, setLangCode] = useState("");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const handleLangCodeInSession = async (value) => {
    await setLanguageCodeInSession(value);
    await setLangCode(value);

    let homeUrl = "/" + value + "/";
    window.location.href = homeUrl;
    // navigate(homeUrl, { replace: true });
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["TopHeader"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <div className="language-block">
      <div className="language-dropdown">
        <Dropdown isOpen={openLang} toggle={toggleLang}>
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={openLang}
            className="language-dropdown-click"
            style={{ textTransform: "uppercase" }}
          >
            {langCodeArray?.find((x) => x.langCode === langCode)?.langCode}
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </DropdownToggle>
          <ul className={`language-dropdown-open ${openLang ? "" : "open"}`}>
            <li>
              <a onClick={() => handleLangCodeInSession("en")} href="#">
                English
              </a>
            </li>
            {/* <li>
              <a onClick={() => handleLangCodeInSession("ar")} href="#">
                Vietnamese
              </a>
            </li> */}
          </ul>
        </Dropdown>
      </div>
    </div>
  );
};

export default Languages;
