import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";

var settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  infinite: true,
  arrows: true,
};

const BannerSlider = () => {
  const [elemOne, setElemOne] = useState({});
  const [bannerList, setBannerList] = useState([]);

  const handleBannerButtonClickUrl = (url) => {
    if (url !== undefined && url !== null && url.length > 0) {
      window.location.href = url;
    } else {
      return false;
    }
  };

  const onMouseHover = (e) => {
    const pageX = e.clientX - window.innerWidth / 1;
    const pageY = e.clientY - window.innerHeight / 1;
    var elemOne = {
      transform:
        "translateX(" +
        (10 + pageX / 150) +
        "%) translateY(" +
        (1 + pageY / 150) +
        "%)",
    };
    setElemOne(elemOne);
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      //--Get product detail
      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_HOME_SCREEN_BANNER"],
        null,
        param,
        headers,
        "POST",
        true
      );
      // console.log(response);
      if (response !== null && response.data !== null) {
        await setBannerList(JSON.parse(response.data.data));
      }
    };

    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <section className="theme-slider" id="theme-slider">
        <div className="custom-container">
          <Row>
            <Col>
              <div className="slide-1">
                <Slider {...settings}>
                  {bannerList
                    .filter((x) => x.ThemeTypeID === 2)
                    .map((banner, i) => (
                      <div
                        key={i}
                        className="slider-banner slide-banner-4"
                        onMouseMove={(e) => onMouseHover(e)}
                      >
                        <Row className="home-banner-bg flex-column-reverse flex-lg-row align-items-center">
                          <Col sm="12" md="12" lg="5">
                            <div className="slider-banner-contain h-md-100 justify-content-md-center  mb-3 mb-md-3 mb-lg-0">
                              <div>
                                <h3 className="home-top-title">
                                  {banner.TopTitle}
                                </h3>
                                <h1>{banner.MainTitle}</h1>
                                <h2>{banner.BottomTitle}</h2>
                                <Link
                                  className="btn home-btn-banner-left btn-primary px-5 py-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleBannerButtonClickUrl(
                                      banner.LeftButtonUrl
                                    );
                                  }}
                                >
                                  {banner.LeftButtonText ?? "Shop Now"}
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col sm="12" md="12" lg="7">
                            <div className="slider-img home-banner-bg pe-lg-3">
                              <div
                                style={elemOne}
                                id="img-1"
                                className="h-100 me-lg-4 d-flex align-items-center justify-content-center"
                              >
                                <img
                                  src={banner.BannerImgUrl}
                                  className="img-fluid"
                                  alt="slider"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <div className="slider-img home-banner-bg">
                        <ul className="layout5-slide-1">
                          <li style={elemOne} id="img-1">
                            <img src={banner.BannerImgUrl} className="img-fluid" alt="slider" />
                          </li>
                        </ul>
                      </div>
                      <div className="slider-banner-contain">
                        <div>
                          <h3 className="home-top-title">{banner.TopTitle}</h3>
                          <h1>{banner.MainTitle}</h1>
                          <h2>{banner.BottomTitle}</h2>

                          <Link className="btn home-btn-banner-left"
                            onClick={(e) => {
                              e.preventDefault();
                              handleBannerButtonClickUrl(banner.LeftButtonUrl);
                            }}
                          >
                            {banner.LeftButtonText}
                          </Link>

                        </div>
                      </div> */}
                      </div>
                    ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default BannerSlider;
