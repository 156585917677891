import { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Media, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import aboutImg from "../../../resources/custom/images/about-us.jpg";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About Us" parent="Home" />

      <section className="about-page bg-light">
        <div className="custom-container" style={{ maxWidth: "800px" }}>
          <h4 className="text-center">About our Store</h4>
          <div className="banner-section">
            <Media src={aboutImg} className="img-fluid w-100" alt="" />
          </div>
          <Row>
            <Col lg="12">
              <h4>About our Store</h4>
              <p className="mb-2">
                Welcome to <b>V1Hub Shop </b>, your ultimate destination for
                discovering and purchasing unique 3D printed products. At V1Hub
                Shop, we celebrate the fusion of technology and creativity by
                showcasing a diverse range of items crafted by talented 3D
                printing artisans from around the globe
              </p>
              <p className="mb-2">
                <b>Exclusive Products:</b> From intricate jewelry to functional
                home decor, hobby figure, explore a curated selection of
                one-of-a-kind 3D printed items.
              </p>
              <p className="mb-2">
                <br></br>
                <b>Why Shop at V1Hub Shop? </b>
              </p>
              <p className="mb-2">
                <b>Quality Craftsmanship:</b> Each product is meticulously
                crafted and undergoes a thorough quality check to ensure your
                satisfaction.
                <br />
                <b>Community Support:</b> By shopping with us, you’re supporting
                independent designers and contributing to the growth of the 3D
                printing community.
                <br />
                <b>Eco-Conscious Shopping:</b> We prioritize sustainability by
                offering products made with eco-friendly materials and
                packaging.
              </p>
              <br></br>
              <p>
                Embark on a journey of discovery and inspiration at V1Hub Shop,
                where innovation meets artistry. Elevate your lifestyle with
                products that tell a story. Start exploring today!
              </p>
            </Col>
          </Row>
        </div>
      </section>

      {/* <CustomerTestimonial /> */}
    </>
  );
};

export default About;
