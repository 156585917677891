import { MakeApiCallAsync } from "helpers/ApiHelpers";
import { GetDefaultCurrencyCode } from "helpers/CommonHelper";
import Config from "helpers/Config";
import { LOADER_DURATION } from "helpers/Constants";
import { showErrorMsg, showSuccessMsg } from "helpers/ValidationHelper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import rootAction from "stateManagment/actions/rootAction";

// export enum PaymentStatus {
//   PAID = 'PAID',
//   PENDING = 'PENDING',
//   PROCESSING = 'PROCESSING',
//   CANCELLED = 'CANCELLED',
// }

// code: number;
// 00 - Thành công
// 01 - Invalid Params
// id: string;
// 2e4acf1083304877bf1a8c108b30cccd	Payment Link Id	string
// cancel: boolean;
// Trạng thái hủy
// true - Đã hủy thanh toán
// false - Đã thanh toán hoặc chờ thanh toán
// status: PaymentStatus;
// Trạng thái thanh toán
// orderCode: number;
// Mã đơn hàng number

const PayosRedirectUrl = () => {
  const [searchParams] = useSearchParams();
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const dispatch = useDispatch();

  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );

  // const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");

  const currency = useRef(GetDefaultCurrencyCode());
  const [cartItemsSession, setCartItemsSession] = useState([]);

  const stringify = localStorage.getItem("v1shop_createOrder");
  const createOrder = stringify ? JSON.parse(stringify) : undefined;

  // const paymentCode = searchParams.get('code');
  // const paymentId = searchParams.get('id');
  // const paymentCancel = searchParams.get('cancel');
  // const paymentStatus = searchParams.get('status');
  const paymentOrderCode = searchParams.get("orderCode");
  // http://localhost:7248/payos/redirect-url?code=00&id=2c2cad4a85af4352aafa046dca286eb3&cancel=false&status=PAID&orderCode=1728274299079
  // http://localhost:3000/payos/redirect-url?code=00&id=aab5d8bf55e74058a00314108b15a8b4&cancel=false&status=PAID&orderCode=1696781113710

  useEffect(() => {
    let cartItems = JSON.parse(cartJsonDataSession ?? "[]");
    console.log(cartItems);

    if (
      cartItems.filter((obj) => obj.Currency == currency.current).length > 0
    ) {
      setCartItemsSession(cartItems);
    } else {
      let updatedCartItems = cartItems.map((obj) => {
        return { ...obj, Currency: currency.current };
      });
      setCartItemsSession(updatedCartItems);
    }
  }, [cartJsonDataSession, currency]);
  const handleCreateOrder = useCallback(async () => {
    try {
      const headersStrip = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const paramSrip = {
        requestParameters: {
          UserID: loginUser.UserID,
          OrderNote: createOrder.orderNote,
          cartJsonData: JSON.stringify(cartItemsSession),
          CouponCode: createOrder.couponCode || "",
          PaymentMethod: 7,
          paymentToken: "",
          payPalOrderConfirmJson: "",
          recordValueJson: "[]",
          payOSData: paymentOrderCode,
        },
      };

      const stripServerResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["POST_CUSTOMER_ORDER"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramSrip,
        headersStrip,
        "POST",
        true
      );
      if (
        stripServerResponse !== null &&
        stripServerResponse.data !== null &&
        stripServerResponse.status === 200
      ) {
        let stripServerResponseDetail = JSON.parse(
          stripServerResponse.data.data !== undefined &&
            stripServerResponse.data.data !== ""
            ? stripServerResponse.data.data
            : "[]"
        );

        if (
          stripServerResponseDetail.length > 0 &&
          stripServerResponseDetail[0].ResponseMsg !== undefined &&
          stripServerResponseDetail[0].ResponseMsg ===
            "Order Placed Successfully"
        ) {
          showSuccessMsg("Order Placed Successfully!");

          setTimeout(function () {
            //--clear customer cart
            dispatch(rootAction.cartAction.setCustomerCart("[]"));
            dispatch(rootAction.cartAction.SetTotalCartItems(0));
            localStorage.setItem("cartItems", "[]");
          }, 1000);

          window.opener.isPaymentSuccess = true;
          window.close();
        } else {
          showErrorMsg("An error occured. Please try again!");
        }
      } else {
        showErrorMsg("An error occured. Please try again!");
      }
    } catch (err) {
      showErrorMsg("An error occured. Please try again!");
      console.log(err.message);

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  }, [
    cartItemsSession,
    loginUser.UserID,
    createOrder,
    paymentOrderCode,
    dispatch,
  ]);

  const CheckPaymentStatus = useCallback(async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const apiResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["PAYOS_TRANSACTION_STATUS"] +
        "/" +
        paymentOrderCode,
      "", //subUrl API
      "", //  params
      headers,
      "GET",
      true
    );
    console.log(apiResponse);

    return apiResponse;
  }, [paymentOrderCode]);

  useEffect(() => {
    let intervalId;
    let elapsedSeconds = 0;

    const checkStatus = async () => {
      try {
        if (!paymentOrderCode) return;
        const res = await CheckPaymentStatus();
        console.log(res);

        const checkStatusAPI = res.status === 200;
        const checkStatus = res.data.isSuccess;
        console.log(checkStatus, checkStatusAPI);

        if (checkStatus || checkStatusAPI) {
          clearInterval(intervalId);
          console.log("payment success");
          handleCreateOrder();
        } else {
          elapsedSeconds += 5;
          if (elapsedSeconds >= 90) {
            clearInterval(intervalId);
            window.opener.isPaymentFalse = true;
            window.close();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    intervalId = setInterval(checkStatus, 5000);

    checkStatus();

    return () => {
      clearInterval(intervalId);
    };
  }, [paymentOrderCode, handleCreateOrder, CheckPaymentStatus]);

  return (
    <div className="d-flex justify-content-center mt-3">
      <Spinner className="me-2" animation="border" variant="primary" />
      <h3>Processing for create order</h3>
    </div>
  );
};

export default React.memo(PayosRedirectUrl);
